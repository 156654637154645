<template>
  <div id="serviceView">
    <SimpleHero
      v-if="simpleHeroData"
      :simple-hero-data="simpleHeroData"
      @loaded="removeLoadingContainer"
    />
    <div class="container">
      <div class="mobile-index">
        <ContentSectionNavigation
          v-if="contentSectionsWithTitles.length"
          :content-sections="contentSectionsWithTitles"
        />
      </div>

      <div class="content">
        <ContentSectionContainer :content-sections="contentSections" />
      </div>

      <div class="index">
        <ContentSectionNavigation
          v-if="contentSectionsWithTitles.length"
          :content-sections="contentSectionsWithTitles"
        />
        <div class="service-usage">
          <p>
            We use {{ pageTitle }} to treat a wide range of symptoms &
            conditions.
          </p>
        </div>
        <SymptomsConditionsList />
      </div>

      <div class="information-container">
        <div class="reprint-permission-container">
          <h2>Reprinted with Permission</h2>
          <div class="gray-container" v-html="reprintPermission"></div>
        </div>
        <div class="more-information-container">
          <h2>More Information</h2>
          <p>
            Thank you for your interest in our services. Please feel free to
            contact us with additional questions, or to schedule a complementary
            consultation.
          </p>
          <AnchorLink class="anchor-link" anchor-link="/contact">
            <FontAwesomeIcon icon="comment-alt" /> Contact Us
          </AnchorLink>
        </div>
      </div>

      <div class="mobile-index">
        <div class="service-usage">
          <p>
            We use {{ pageTitle }} to treat a wide range of symptoms &
            conditions.
          </p>
        </div>
        <SymptomsConditionsList />
      </div>

      <OtherServicesContainer
        class="other-services-container"
        v-if="servicesListData.length"
        :services-list-data="servicesListData"
        :current-service-slug="$route.path"
      />
    </div>
  </div>
</template>

<script>
import helpers from '@/api/helpers/helpers'
import api from '@/api'
import SimpleHero from '@/components/Hero/SimpleHero'
import ContentSectionContainer from '@/components/Content/ContentSectionContainer'
import ContentSectionNavigation from '@/components/Content/ContentSectionNavigation'
import OtherServicesContainer from '@/components/Services/OtherServicesContainer'
import AnchorLink from '@/components/UI/AnchorLink'
import SymptomsConditionsList from '@/components/SymptomsConditions/SymptomsConditionsList'
import { mapMutations } from 'vuex'

export default {
  name: 'Service',
  components: {
    SymptomsConditionsList,
    AnchorLink,
    OtherServicesContainer,
    ContentSectionNavigation,
    ContentSectionContainer,
    SimpleHero,
  },
  data() {
    return {
      pageTitle: '',
      simpleHeroData: null,
      servicesListData: [],
      contentSections: [],
      reprintPermission: '',
    }
  },
  computed: {
    contentSectionsWithTitles() {
      return this.contentSections.filter(
        (contentSection) =>
          !!contentSection.sectionTitle && contentSection.sectionTitle.length
      )
    },
  },
  mounted() {
    this.loadService()
    this.loadServicesList()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    /**
     *
     */
    loadService() {
      const routeSlug = this.$route.params.slug

      this.setServiceData(
        helpers.readStoredResponse(`view.service.${routeSlug}`)
      )

      api.views.loadService(routeSlug).then((response) => {
        this.setServiceData(response)
      })
    },
    /**
     *
     * @param response
     */
    setServiceData(response) {
      if (!response || !response.entry) return

      const entry = response.entry

      this.pageTitle = entry.title
      this.simpleHeroData = entry.simpleHero[0] || null
      this.contentSections = entry.contentSections || []
      this.reprintPermission = entry.reprintPermission || ''

      if (!this.simpleHeroData) {
        this.removeLoadingContainer()
      }
    },
    /**
     *
     */
    removeLoadingContainer() {
      setTimeout(() => {
        this.setPageLoadingFalse()
      }, 500)
    },
    /**
     *
     */
    loadServicesList() {
      this.setServicesListData(
        helpers.readStoredResponse('globals.services-list')
      )
      api.globals.loadServicesList().then((response) => {
        this.setServicesListData(response)
      })
    },
    /**
     * @param response
     */
    setServicesListData(response) {
      if (!response || !response.entry) return

      this.servicesListData =
        response.entry.servicesList[0].selectedServices || []
    },
  },
}
</script>

<style lang="scss">
#serviceView {
  @apply bg-black;
  margin-bottom: 200px;
  padding: 60px 0;

  .container {
    @apply flex flex-wrap;
    padding-top: 20px;

    .content {
      @apply w-full;
      padding-right: 0;
    }

    .index,
    .mobile-index {
      @apply w-full;

      &:not(.mobile-index) {
        @apply hidden;
        padding-left: 15px;
      }

      .service-usage {
        background-color: #231f20;
        border-left: medium none;
        border-right: medium none;
        border-top: 2px groove rgba(113, 57, 0, 0.5);
        border-bottom: 2px groove rgba(113, 57, 0, 0.3);
        padding: 20px;
        margin-bottom: 20px;

        p {
          font-family: Roboto, sans-serif;
          font-size: 16px;
          font-weight: 300;
          color: #cfcfcf;
        }
      }
    }

    .information-container {
      @apply w-full flex flex-wrap text-white text-left;
      border-top: 1px solid #4f4f4f;
      border-bottom: 1px solid #4f4f4f;
      margin-top: 20px;
      margin-bottom: 20px;
      padding-bottom: 40px;

      .reprint-permission-container,
      .more-information-container {
        @apply w-full;

        h2 {
          font-family: FjallaOneRegular, sans-serif;
          font-size: 28px;
          margin-top: 20px;
          margin-bottom: 0.5em;
        }
      }

      .reprint-permission-container {
        margin-right: 10px;
        .gray-container {
          @apply text-left;
          background-color: #231f20;
          border-left: medium none;
          border-right: medium none;
          border-top: 2px groove rgba(113, 57, 0, 0.5);
          border-bottom: 2px groove rgba(113, 57, 0, 0.3);
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
          font-size: 16px;
          font-weight: 400;
          padding: 20px;

          a {
            color: #f17800 !important;
          }
        }
      }

      .more-information-container {
        margin-left: 10px;

        .anchor-link {
          @apply block text-center;
          text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
          font-size: 20px;
          font-weight: 700;
          border-radius: 3px;
          padding-top: 1.03125em;
          padding-bottom: 1.03125em;
          background-color: #f17800;
          border-color: #be5f00;
          margin-top: 1em;
          transition: background-color 0.3s ease-in;

          &:hover {
            background-color: #be5f00;
          }
        }
      }
    }

    .other-services-container {
      @apply w-full;
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .information-container {
        .reprint-permission-container,
        .more-information-container {
          h2 {
            font-size: 46px;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .content {
        @apply w-2/3;
        padding-right: 15px;
      }

      .index,
      .mobile-index {
        @apply hidden;

        &:not(.mobile-index) {
          @apply w-1/3 block;
        }
      }

      .information-container {
        @apply flex-nowrap;

        .reprint-permission-container,
        .more-information-container {
          @apply w-1/2;
        }
      }
    }
  }
}
</style>
